import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'; // Usando o useNavigate e useLocation para navegação
import './Login.css'; // Reutilizando o CSS do Login

const ChangePassword = ({ language }) => { // Recebendo a linguagem como prop
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    
    // Extraindo email e token da rota
    const { email, token } = location.state || {};

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (!email || !token) {
            setErrorMessage(language === 'pt' ? 'Dados insuficientes. Por favor, tente novamente.' : language === 'es' ? 'Datos insuficientes. Por favor, inténtelo de nuevo.' : 'Insufficient data. Please try again.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage(language === 'pt' ? 'As senhas não coincidem.' : language === 'es' ? 'Las contraseñas no coinciden.' : 'Passwords do not match.');
            return;
        }

        if (newPassword.length < 8) {
            setErrorMessage(language === 'pt' ? 'A senha deve ter no mínimo 8 caracteres.' : language === 'es' ? 'La contraseña debe tener al menos 8 caracteres.' : 'The password must be at least 8 characters long.');
            return;
        }

        try {
            const response = await axios.post('https://2824cdfd-4984-4a62-b8b0-57e1a82ae482-00-2ufqu5w2gizqz.worf.replit.dev:8000/auth/forgot_pass', {
                email,
                token,
                senha: newPassword
            });

            if (response.status === 200) {
                setSuccessMessage(language === 'pt' ? 'Senha alterada com sucesso! Agora você pode fazer login com a nova senha.' : language === 'es' ? '¡Contraseña cambiada con éxito! Ahora puede iniciar sesión con la nueva contraseña.' : 'Password changed successfully! You can now log in with your new password.');
                setTimeout(() => {
                    navigate('/login'); // Redirecionar para a tela de login após sucesso
                }, 2000);
            } else {
                setErrorMessage(language === 'pt' ? 'Erro ao alterar a senha. Tente novamente.' : language === 'es' ? 'Error al cambiar la contraseña. Inténtelo de nuevo.' : 'Error changing password. Please try again.');
            }
        } catch (error) {
            setErrorMessage(language === 'pt' ? 'Erro ao alterar a senha. Verifique os dados fornecidos.' : language === 'es' ? 'Error al cambiar la contraseña. Verifique los datos proporcionados.' : 'Error changing password. Please check the provided data.');
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2>{language === 'pt' ? 'Trocar Senha' : language === 'es' ? 'Cambiar Contraseña' : 'Change Password'}</h2>
                {successMessage && <p className="success-message">{successMessage}</p>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Nova Senha' : language === 'es' ? 'Nueva Contraseña' : 'New Password'}</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Confirmar Nova Senha' : language === 'es' ? 'Confirmar Nueva Contraseña' : 'Confirm New Password'}</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button" disabled={newPassword !== confirmPassword || newPassword.length < 8}>
                        {language === 'pt' ? 'Trocar Senha' : language === 'es' ? 'Cambiar Contraseña' : 'Change Password'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
