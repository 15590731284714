import React, { useEffect, useState } from 'react';
import './Welcome.css';
import axios from 'axios';

const Welcome = ({ language }) => {
    const [user, setUser] = useState({ name: 'Usuário', occurrences: 0 });
    const [idCliente, setIdCliente] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser && storedUser.id_cliente) {
            setUser({ name: storedUser.name });
            setIdCliente(storedUser.id_cliente);
        }
    }, []);

    useEffect(() => {
        if (idCliente !== null) {
            fetchOccurrencesCount(idCliente);
        }
    }, [idCliente]);

    const fetchOccurrencesCount = async (id_user) => {
        try {
            const response = await axios.post('https://2824cdfd-4984-4a62-b8b0-57e1a82ae482-00-2ufqu5w2gizqz.worf.replit.dev:8000/feedback/get_occurrences', {
                id_user: id_user,
                tipo: 1
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            if (response.status === 200 && response.data.count !== undefined) {
                setUser(prevState => ({
                    ...prevState,
                    occurrences: response.data.count
                }));
            } else {
                console.error(language === 'pt' ? 'Erro ao obter a contagem de alertas:' : language === 'es' ? 'Error al obtener el recuento de alertas:' : 'Error fetching occurrences count:', response.data);
            } 
        } catch (error) {
            console.error(language === 'pt' ? 'Erro ao buscar a contagem de alertas:' : language === 'es' ? 'Error al buscar el recuento de alertas:' : 'Error fetching occurrences count:', error);
        }
    };

    return (
        <div className="welcome-page">
            <h1>{language === 'pt' ? 'Bem-vindo,' : language === 'es' ? 'Bienvenido,' : 'Welcome,'} {user.name}!</h1>
            <p>{language === 'pt' ? 'Você tem' : language === 'es' ? 'Tienes' : 'You have'} {user.occurrences} {language === 'pt' ? 'ocorrência(s) hoje.' : language === 'es' ? 'ocurrencia(s) hoy.' : 'occurrence(s) today.'}</p>
            <p>{language === 'pt' ? 'Esperamos que tenha um ótimo dia!' : language === 'es' ? '¡Esperamos que tengas un gran día!' : 'We hope you have a great day!'}</p>
        </div>
    );
};

export default Welcome;
