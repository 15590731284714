import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importar o useNavigate
import './Login.css';

const ForgotPassword = ({ language }) => { // Recebendo a linguagem como prop
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Inicializando o hook

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        try {
            const response = await axios.post('https://2824cdfd-4984-4a62-b8b0-57e1a82ae482-00-2ufqu5w2gizqz.worf.replit.dev:8000/auth/generate_token', {
                email
            });

            if (response.status === 200) {
                setSuccessMessage(language === 'pt' ? 'Um código de recuperação de senha foi enviado para o seu e-mail.' : language === 'es' ? 'Se ha enviado un código de recuperación de contraseña a su correo electrónico.' : 'A password recovery code has been sent to your email.');
                setTimeout(() => {
                    // Redirecionar para a página de validação do código e passar o e-mail como parâmetro
                    navigate('/validate-code', { state: { email } });
                }, 1000);
            } else {
                setErrorMessage(language === 'pt' ? 'Erro ao enviar código de recuperação. Tente novamente.' : language === 'es' ? 'Error al enviar el código de recuperación. Inténtalo de nuevo.' : 'Error sending recovery code. Please try again.');
            }
        } catch (error) {
            setErrorMessage(language === 'pt' ? 'Erro ao enviar código de recuperação. Verifique o e-mail informado.' : language === 'es' ? 'Error al enviar el código de recuperación. Verifique el correo electrónico ingresado.' : 'Error sending recovery code. Please check the entered email.');
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2>{language === 'pt' ? 'Recuperar Senha' : language === 'es' ? 'Recuperar Contraseña' : 'Recover Password'}</h2>
                {successMessage && <p className="success-message">{successMessage}</p>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Insira o e-mail de sua conta' : language === 'es' ? 'Ingrese el correo electrónico de su cuenta' : 'Enter your account email'}</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">
                        {language === 'pt' ? 'Enviar código' : language === 'es' ? 'Enviar código' : 'Send code'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
